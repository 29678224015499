<template>
  <div class="container">
    <div class="totals-container">
      <div class="total-item total-ready">
        جاهز: <span>{{ totalReady }}</span>
      </div>
      <div class="total-item total-in-progress">
        قيد التنفيذ: <span>{{ totalInProgress }}</span>
      </div>
      <div class="total-item total-overall">
        الإجمالي: <span>{{ totalInvoices }}</span>
      </div>
    </div>
    <h2>فواتير الصيانة</h2>
    <input 
      type="text" 
      v-model="searchQuery" 
      placeholder="ابحث عن الفواتير..." 
      class="search-input"
    />

    <!-- Fixed table header -->
    <div class="table-header">
      <table class="invoice-table">
        <thead>
          <tr>
            <th>#</th>
            <th>اسم العميل</th>
            <th>رقم الهاتف</th>
            <th>العنوان</th>
            <th>حالة الفاتورة</th>
            <th>مندوب المبيعات</th>
            <th>الوقت المتوقع للإنجاز</th>
            <th>الملاحظات</th>
            <th>أنشئت بواسطة</th>
            <th>تاريخ الإنشاء</th>
            <th v-if="canManageInvoices">الإجراءات</th>
          </tr>
        </thead>
      </table>
    </div>

    <!-- Scrollable table body with limited items -->
    <div class="table-body">
      <table class="invoice-table">
        <tbody>
          <tr v-for="(invoice, index) in paginatedInvoices" :key="invoice.invoiceId">
            <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
            <td>{{ invoice.customerName }}</td>
            <td>{{ invoice.phoneNumber }}</td>
            <td>{{ invoice.address }}</td>
            <td v-if="canManageInvoices">
              <div class="select-wrapper">
                <select 
                  v-model="invoice.invoiceStatus" 
                  @change="confirmStatusChange(invoice)"
                  class="status-dropdown"
                >
                  <option value="READY">جاهز</option>
                  <option value="IN_PROGRESS">قيد التنفيذ</option>
                  <option value="DELIVERED">تم التسليم</option>
                  <option value="UNDEFINED">غير محدد</option>
                </select>
              </div>
            </td>
            <td v-else :class="getStatusClass(invoice.invoiceStatus)">
              {{ invoice.invoiceStatus }}
            </td>
            <td>{{ invoice.salesPerson }}</td>
            <td :class="getTimeColor(invoice.estimatedTime)">
              {{ invoice.invoiceStatus === 'IN_PROGRESS' ? formatEstimatedTime(invoice.estimatedTime) : 'الوقت انتهى' }}
            </td>
            <td>
              <div class="notes-container">
                {{ truncateText(invoice.notes, 20) }}
                <button v-if="invoice.notes && invoice.notes.length > 20" @click="showFullText(invoice.notes)" class="show-button">عرض كامل</button>
              </div>
            </td>
            <td>{{ invoice.createdBy }}</td>
            <td>{{ new Date(invoice.createdDate).toLocaleString() }}</td>
            <td v-if="canManageInvoices">
              <button @click="goToEditInvoice(invoice.invoiceId)" class="edit-button">تعديل</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination Controls -->
    <div class="pagination">
      <button @click="previousPage" :disabled="currentPage === 1">السابق</button>
      <span>صفحة {{ currentPage }} من {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">التالي</button>
    </div>

    <div class="button-group">
      <button v-if="canManageInvoices" @click="goToAddInvoice" class="add-button">إضافة فاتورة</button>
      <button v-if="isAdmin" @click="goToAddUser" class="add-button">إضافة مستخدم</button>
      <button @click="logout" class="logout-button">تسجيل الخروج</button>
    </div>

    <!-- Full Text Modal -->
    <div v-if="isModalVisible" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <h3>النص الكامل</h3>
        <p>{{ fullText }}</p>
        <button @click="closeModal" class="close-button">إغلاق</button>
      </div>
    </div>

    <!-- Confirm Modal -->
    <div v-if="isConfirmModalVisible" class="modal-overlay" @click="closeConfirmModal">
      <div class="modal-content" @click.stop>
        <h3>تأكيد تغيير الحالة</h3>
        <p>هل أنت متأكد أنك تريد تغيير الحالة إلى "{{ selectedStatus }}"؟</p>
        <button @click="updateInvoiceStatus" class="confirm-button">تأكيد</button>
        <button @click="closeConfirmModal" class="cancel-button">إلغاء</button>
      </div>
    </div>

    <!-- Time Input Modal -->
    <div v-if="isTimeModalVisible" class="modal-overlay" @click="closeTimeModal">
      <div class="modal-content" @click.stop>
        <h3>إدخال الوقت المتوقع</h3>
        <label for="hours">الساعات:</label>
        <input type="number" v-model="estimatedHours" id="hours" min="0" max="24" />
        <label for="minutes">الدقائق:</label>
        <input type="number" v-model="estimatedMinutes" id="minutes" min="0" max="59" />
        <button @click="submitEstimatedTime" class="confirm-button">إرسال</button>
        <button @click="closeTimeModal" class="cancel-button">إلغاء</button>
      </div>
    </div>

    <!-- Footer with Copyright -->
    <footer class="footer">
      <p>Powered by: Yousif</p>
      <p>Beta version</p>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import { getLocalIPAddress } from '../ipUtils'; // Import getLocalIPAddress
import { jwtDecode } from 'jwt-decode'; // Correct import of jwtDecode
import moment from 'moment-timezone';
import * as signalR from '@microsoft/signalr'; // Import SignalR

export default {
  data() {
    return {
      invoices: [],
      isAdmin: false, 
      isEmployee: false, 
      searchQuery: '', 
      isModalVisible: false,
      fullText: '',
      selectedInvoice: null,
      selectedStatus: '',
      isConfirmModalVisible: false,
      isTimeModalVisible: false, // New modal state for time input
      hubConnection: null, // SignalR connection
      currentPage: 1,
      itemsPerPage: 10, // Limit to 10 invoices per page
      previousStatuses: {}, // لتتبع حالة الفواتير السابقة
      estimatedHours: 0, // ساعات الوقت المتوقع
      estimatedMinutes: 0, // دقائق الوقت المتوقع
    };
  },
  computed: {
    // Search across all invoices before paginating
    filteredInvoices() {
      const query = this.searchQuery.toLowerCase();

      // إنشاء نسخة من الفواتير لتجنب التأثيرات الجانبية
      let sortedInvoices = [...this.invoices];

      // ترتيب الفواتير من الأحدث إلى الأقدم
      sortedInvoices.sort((a, b) => {
        return new Date(b.createdDate) - new Date(a.createdDate);
      });

      // تصفية الفواتير بناءً على البحث
      return sortedInvoices.filter(invoice => 
        invoice.customerName.toLowerCase().includes(query) ||
        invoice.phoneNumber.toLowerCase().includes(query) ||
        invoice.address.toLowerCase().includes(query) ||
        invoice.invoiceStatus.toLowerCase().includes(query) ||
        invoice.salesPerson.toLowerCase().includes(query) || 
        invoice.notes.toLowerCase().includes(query) || 
        invoice.createdBy.toLowerCase().includes(query)
      );
    },
    // Apply pagination to the filtered results
    paginatedInvoices() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredInvoices.slice(startIndex, endIndex);
    },
    totalInvoices() {
      return this.filteredInvoices.length; // Return the length of filtered invoices
    },
    totalReady() {
      return this.invoices.filter(invoice => invoice.invoiceStatus === 'READY').length;
    },
    totalInProgress() {
      return this.invoices.filter(invoice => invoice.invoiceStatus === 'IN_PROGRESS').length;
    },
    totalDelivered() {
      return this.invoices.filter(invoice => invoice.invoiceStatus === 'DELIVERED').length;
    },
    canManageInvoices() {
      return this.isAdmin || this.isEmployee;
    },
    totalPages() {
      return Math.ceil(this.filteredInvoices.length / this.itemsPerPage);
    }
  },
  watch: {
    searchQuery() {
      // Reset to the first page whenever the search query changes
      this.currentPage = 1;
    }
  },
  methods: {
    getStatusClass(status) {
      if (status === 'READY') {
        return 'status-ready';
      } else if (status === 'IN_PROGRESS') {
        return 'status-progress';
      } else if (status === 'DELIVERED') {
        return 'status-delivered'; 
      } else if (status === 'UNDEFINED') {
        return 'status-undefined';
      }
      return '';
    },
    formatEstimatedTime(estimatedTime) {
      if (!estimatedTime) return 'لم يتم التحديد';
      return moment.utc(estimatedTime).tz('Asia/Baghdad').format('h:mm A');
    },
    getTimeColor(estimatedTime) {
      if (!estimatedTime) return '';
      const time = moment.utc(estimatedTime).tz('Asia/Baghdad');
      const hour = time.hour();

      if (hour >= 9 && hour < 14) {
        return 'business-hours';
      } else {
        return 'evening-hours';
      }
    },
    goToAddInvoice() {
      this.$router.push('/add-invoice');
    },
    goToAddUser(){
      this.$router.push('/add-user');
    },
    goToEditInvoice(invoiceId) {
      this.$router.push(`/edit-invoice/${invoiceId}`);
    },
    logout() {
      localStorage.removeItem('token');
      this.$router.push('/login');
    },
    showFullText(text) {
      this.fullText = text;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    closeTimeModal() {
      this.isTimeModalVisible = false;
    },
    truncateText(value, length) {
      if (value && value.length > length) {
        return value.substring(0, length) + '...';
      }
      return value;
    },
    confirmStatusChange(invoice) {
      this.selectedInvoice = invoice;
      this.selectedStatus = invoice.invoiceStatus;
      // Show time modal if status is IN_PROGRESS
      if (this.selectedStatus === 'IN_PROGRESS') {
        this.isTimeModalVisible = true;
      } else {
        this.isConfirmModalVisible = true;
      }
    },
    async submitEstimatedTime() {
      // Convert hours and minutes to a single datetime value
      const totalMinutes = parseInt(this.estimatedHours) * 60 + parseInt(this.estimatedMinutes);
      const estimatedTime = moment.utc().add(totalMinutes, 'minutes').toISOString();
      this.selectedInvoice.estimatedTime = estimatedTime;
      this.isTimeModalVisible = false;
      this.isConfirmModalVisible = true; // Show confirm modal
    },
    async updateInvoiceStatus() {
      try {
        const token = localStorage.getItem('token');
        const localIP = getLocalIPAddress(); // Get dynamic local IP
        const apiBaseUrl = `https://${localIP}`; // Dynamically construct base URL

        // نسخة من الفاتورة المحددة مع تعديل الحالة
        let updatedInvoice = { ...this.selectedInvoice, invoiceStatus: this.selectedStatus };

        // إذا كانت الحالة هي "قيد التنفيذ"، نتحقق من أن estimatedTime ليست فارغة
        if (this.selectedStatus === 'IN_PROGRESS') {
          // إذا لم يكن هناك وقت محدد، نعين الوقت إلى "لم يتم التحديد"
          if (!updatedInvoice.estimatedTime || updatedInvoice.estimatedTime === 'لم يتم التحديد') {
            updatedInvoice.estimatedTime = null; // لا نرسل الوقت إذا كان غير محدد
          }
        } else {
          // للحالات الأخرى، نعيد ضبط الوقت
          updatedInvoice.estimatedTime = null;
        }

        // طلب التحديث إلى الباك اند
        await axios.put(`${apiBaseUrl}/api/Invoices/${updatedInvoice.invoiceId}`, updatedInvoice, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // إخفاء نموذج التأكيد
        this.isConfirmModalVisible = false;
        this.selectedInvoice = null;
      } catch (err) {
        // طباعة رسالة الخطأ وتفاصيل الاستجابة
        console.error('Error updating invoice status:', err);
        if (err.response) {
          console.error('Response data:', err.response.data);
          console.error('Response status:', err.response.status);
          console.error('Response headers:', err.response.headers);
        }
        alert('فشل في تحديث حالة الفاتورة. حاول مرة أخرى.');
      }
    },
    closeConfirmModal() {
      this.isConfirmModalVisible = false;
    },
    async setupSignalR() {
      const localIP = getLocalIPAddress(); 
      const apiBaseUrl = `https://${localIP}`;

      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${apiBaseUrl}/notificationHub`)
        .build();

      // Update invoices when received from SignalR
      this.hubConnection.on('ReceiveInvoiceUpdate', (updatedInvoice) => {
        console.log('Received updated invoice:', updatedInvoice); // Debugging log
        const index = this.invoices.findIndex(i => i.invoiceId === updatedInvoice.invoiceId);
        
        // تحقق من الحالة السابقة للفاتورة
        const previousStatus = this.previousStatuses[updatedInvoice.invoiceId];

        if (index !== -1) {
          this.invoices.splice(index, 1, updatedInvoice);
        } else {
          this.invoices.push(updatedInvoice);
        }

        // تحقق من أن الحالة تحولت من أي حالة إلى READY
        if (previousStatus !== 'READY' && updatedInvoice.invoiceStatus === 'READY') {
          this.showNotification(updatedInvoice);
        }

        // تحديث الحالة السابقة
        this.previousStatuses[updatedInvoice.invoiceId] = updatedInvoice.invoiceStatus;
      });

      // Invoice added
      this.hubConnection.on('InvoiceAdded', (newInvoice) => {
        console.log('Received new invoice:', newInvoice); // Debugging log
        this.invoices.push(newInvoice);
        // حفظ الحالة المبدئية للفاتورة
        this.previousStatuses[newInvoice.invoiceId] = newInvoice.invoiceStatus;
      });

      // Invoice updated
      this.hubConnection.on('InvoiceUpdated', (updatedInvoice) => {
        console.log('Received updated invoice:', updatedInvoice); // Debugging log
        const index = this.invoices.findIndex(i => i.invoiceId === updatedInvoice.invoiceId);
        // تحقق من الحالة السابقة للفاتورة
        const previousStatus = this.previousStatuses[updatedInvoice.invoiceId];

        if (index !== -1) {
          this.invoices.splice(index, 1, updatedInvoice);
        }

        // تحقق من أن الحالة تحولت من أي حالة إلى READY
        if (previousStatus !== 'READY' && updatedInvoice.invoiceStatus === 'READY') {
          this.showNotification(updatedInvoice);
        }

        // تحديث الحالة السابقة
        this.previousStatuses[updatedInvoice.invoiceId] = updatedInvoice.invoiceStatus;
      });

      // Invoice deleted
      this.hubConnection.on('InvoiceDeleted', (deletedInvoiceId) => {
        console.log('Received deleted invoice:', deletedInvoiceId); // Debugging log
        this.invoices = this.invoices.filter(i => i.invoiceId !== deletedInvoiceId);
        // إزالة الحالة السابقة عند حذف الفاتورة
        delete this.previousStatuses[deletedInvoiceId];
      });

      try {
        await this.hubConnection.start();
        console.log('SignalR connected');
      } catch (error) {
        console.error('Error while starting SignalR connection: ', error);
      }
    },
    async requestNotificationPermission() {
      if (Notification.permission !== "granted") {
        await Notification.requestPermission();
      }
    },
    showNotification(invoice) {
      // تحقق من أن حالة الفاتورة جاهزة فقط
      if (invoice.invoiceStatus === 'READY') {
        const message = `الفاتورة باسم "${invoice.customerName}" أصبحت جاهزة.`;

        if (Notification.permission === "granted") {
          new Notification('إشعار الفاتورة', {
            body: message,
            icon: require('@/assets/images/noti.png') // ضع مسار الأيقونة المناسب
          });
        } else {
          Notification.requestPermission().then(permission => {
            if (permission === "granted") {
              new Notification('إشعار الفاتورة', {
                body: message,
                icon: require('@/assets/images/noti.png')
              });
            }
          });
        }
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    }
  },
  async created() {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const decodedToken = jwtDecode(token);
      this.isAdmin = decodedToken.role === 'Admin';
      this.isEmployee = decodedToken.role === 'Employee';

      const localIP = getLocalIPAddress(); 
      const apiBaseUrl = `https://${localIP}`; 

      const response = await axios.get(`${apiBaseUrl}/api/Invoices`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      this.invoices = response.data;

      // حفظ الحالات المبدئية للفواتير عند التحميل
      this.invoices.forEach(invoice => {
        this.previousStatuses[invoice.invoiceId] = invoice.invoiceStatus;
      });

      // Setup SignalR connection for real-time updates
      await this.setupSignalR();
      await this.requestNotificationPermission();
    } catch (err) {
      console.error(err);
      this.$router.push('/login');
    }
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background: url('@/assets/images/background.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.container {
  width: 100%;
  max-width: 90%;
  margin: 20px auto;
  padding: 30px;
  background: rgba(34, 47, 62, 0.85);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  text-align: center;
  height: auto;
}

.totals-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1px;
}

.total-item {
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 10px;
}

.total-ready {
  color: #1cc71c; /* اللون الأخضر */
}

.total-in-progress {
  color: #0011ff; /* اللون الأزرق */
}

.total-overall {
  color: #ffffff; /* اللون الأبيض */
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #00aaff;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
  font-size: 1rem;
  color: #0a0a0a;
}

.table-header,
.table-body {
  width: 100%;
  overflow-x: auto; /* تفعيل التمرير الأفقي */
}

.table-header table,
.table-body table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* تثبيت عرض الأعمدة */
}

.table-header {
  position: sticky;
  top: 0;
  z-index: 1;
  margin-bottom: 0;
  background: rgba(0, 123, 255, 0.9); /* لون خلفية رأس الجدول */
}

.table-body {
  max-height: 400px; /* تحديد ارتفاع ثابت */
  overflow-y: scroll; /* تمكين التمرير العمودي */
  border-bottom: 1px solid #ddd;
}

.invoice-table th,
.invoice-table td {
  padding: 15px;
  border-bottom: 1px solid #ddd;
  text-align: center; /* محاذاة النص في الوسط */
  vertical-align: middle; /* محاذاة المحتوى عمودياً في الوسط */
  white-space: nowrap; /* منع التفاف النص */
}

.invoice-table th {
  background: rgba(0, 123, 255, 0.3); /* لون خلفية رأس الجدول */
  color: #fff;
  font-weight: bold;
}

.invoice-table td {
  background: rgba(255, 255, 255, 0.9);
  color: #0a0a0a;
}

.status-dropdown {
  background-color: rgba(255, 255, 255, 0.9);
  color: #0a0a0a;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  width: 100%; /* عرض كامل للقائمة المنسدلة */
}

.select-wrapper {
  width: 100%;
  max-width: 150px; /* تحديد عرض محدد للقائمة المنسدلة */
  margin: auto;
}

.status-ready {
  color: rgb(28, 199, 28) !important;
  font-weight: bold;
}

.status-progress {
  color: rgb(0, 17, 255) !important;
  font-weight: bold;
}

.status-undefined {
  color: rgb(255, 0, 0) !important;
  font-weight: bold;
}

.status-delivered {
  color: rgb(255, 165, 0) !important; /* اللون البرتقالي */
  font-weight: bold;
}

.notes-container {
  display: flex;
  flex-wrap: wrap; /* لف النص إذا كان طويلًا */
  justify-content: center;
  align-items: center;
}

.show-button {
  background-color: #34685e;
  color: #fff;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}

.show-button:hover {
  background-color: #00c6ff;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.2);
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.edit-button,
.add-button,
.logout-button {
  padding: 0.75rem 1.5rem;
  background-color: #34685e;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.edit-button:hover,
.add-button:hover,
.logout-button:hover {
  background-color: #00c6ff;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.2);
}

.add-button {
  background-color: #34685e;
}

.logout-button {
  background-color: #ff4d4f;
}

.logout-button:hover {
  background-color: #ff7875;
  box-shadow: 0 5px 15px rgba(255, 77, 79, 0.2);
}

.error-message {
  margin-top: 1rem;
  color: red;
  font-weight: bold;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  color: #000;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
}

.close-button {
  background-color: #ff4d4f;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.close-button:hover {
  background-color: #ff7875;
}

.confirm-button {
  background-color: #34685e;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-button:hover {
  background-color: #00c6ff;
}

.cancel-button {
  background-color: #ff4d4f;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #ff7875;
}

.business-hours {
  color: green;
}

.evening-hours {
  color: orange;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 20px;
  background-color: #34685e; /* لون الخلفية للأزرار */
  color: #fff; /* لون النص */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 1rem; /* حجم الخط */
  font-weight: 600; /* سمك الخط */
}

.pagination button:disabled {
  background-color: #888; /* لون الزر عند التعطيل */
  cursor: not-allowed; /* مؤشر الفأرة عند التعطيل */
  opacity: 0.7; /* شفافية للزر المعطل */
}

.pagination button:hover:enabled {
  background-color: #00c6ff; /* لون الخلفية عند التفاعل */
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.2); /* تأثير الظل */
}

.pagination span {
  font-size: 1rem; /* حجم النص */
  font-weight: 600; /* سمك الخط */
  color: #fff; /* لون النص */
}

/* تخصيص عرض الأعمدة حسب عددها */
.invoice-table th:nth-child(1),
.invoice-table td:nth-child(1) {
  width: 5%;
}

.invoice-table th:nth-child(2),
.invoice-table td:nth-child(2) {
  width: 15%;
}

.invoice-table th:nth-child(3),
.invoice-table td:nth-child(3) {
  width: 10%;
}

.invoice-table th:nth-child(4),
.invoice-table td:nth-child(4) {
  width: 15%;
}

.invoice-table th:nth-child(5),
.invoice-table td:nth-child(5) {
  width: 10%;
}

.invoice-table th:nth-child(6),
.invoice-table td:nth-child(6) {
  width: 10%;
}

.invoice-table th:nth-child(7),
.invoice-table td:nth-child(7) {
  width: 10%;
}

.invoice-table th:nth-child(8),
.invoice-table td:nth-child(8) {
  width: 10%;
}

.invoice-table th:nth-child(9),
.invoice-table td:nth-child(9) {
  width: 10%;
}

.invoice-table th:nth-child(10),
.invoice-table td:nth-child(10) {
  width: 10%;
}

/* تخصيص العرض لرؤوس الجدول عند عرض الإجراءات */
.invoice-table th:nth-child(11),
.invoice-table td:nth-child(11) {
  width: 5%;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 15px;
  }

  .invoice-table th,
  .invoice-table td {
    padding: 10px;
  }

  h2 {
    font-size: 1.5rem;
  }

  .search-input {
    width: 100%;
    font-size: 0.9rem;
  }

  /* إخفاء بعض الأعمدة في الشاشات الصغيرة */
  .invoice-table th:nth-child(4),
  .invoice-table td:nth-child(4),
  .invoice-table th:nth-child(8),
  .invoice-table td:nth-child(8),
  .invoice-table th:nth-child(10),
  .invoice-table td:nth-child(10) {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 10px;
  }

  h2 {
    font-size: 1.2rem;
  }

  .invoice-table th,
  .invoice-table td {
    padding: 5px;
    font-size: 0.7rem;
  }

  /* إخفاء مزيد من الأعمدة في الشاشات الأصغر */
  .invoice-table th:nth-child(6),
  .invoice-table td:nth-child(6),
  .invoice-table th:nth-child(7),
  .invoice-table td:nth-child(7) {
    display: none;
  }

  .add-button,
  .logout-button,
  .edit-button {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }

  .search-input {
    width: 100%;
    font-size: 0.8rem;
    padding: 8px;
  }
}

/* Footer Style */
.footer {
  margin-top: 30px;
  text-align: center;
  font-size: 0.9rem;
  color: #ffffff;
  font-weight: 300;
}

.footer p {
  margin: 0;
  line-height: 1.5;
}


</style>
