import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../components/UserLogin.vue';
import InvoiceList from '../components/InvoiceList.vue';
import AddInvoice from '../components/AddInvoice.vue';
import EditInvoice from '../components/EditInvoice.vue'; // Import the EditInvoice component
import AddUser from '@/components/AddUser.vue';

const routes = [
  { path: '/', component: UserLogin },
  { path: '/login', component: UserLogin },
  { 
    path: '/invoices', 
    component: InvoiceList,
    meta: { requiresAuth: true } 
  },
  { 
    path: '/add-invoice', 
    component: AddInvoice, 
    meta: { requiresAuth: true } 
  },
  { 
    path: '/edit-invoice/:id', // Define the edit-invoice route with a dynamic segment
    component: EditInvoice,
    meta: { requiresAuth: true } 
  },
  {
    path: '/add-user',
   component: AddUser,
   meta: { requiresAuth: true , requiresAdmin: true}
  
  },

  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token'); 
  const token = localStorage.getItem('token');
  let isAdmin = false;

  if (token) {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    isAdmin = decodedToken.role && decodedToken.role.includes('Admin');
  }

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login'); 
  } else if (to.meta.requiresAdmin && !isAdmin) {
    next('/invoices'); 
  } else {
    next(); 
  }
});

export default router;
