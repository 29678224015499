<template>
  <div class="login-container">
    <div class="login-card">
      <h2 class="login-title">Welcome Back</h2>
      <p class="login-subtitle">Please enter your credentials to log in</p>
      <form @submit.prevent="login" class="login-form">
        <div class="input-group">
          <label for="username" class="input-label">Username</label>
          <div class="input-wrapper">
            <i class="fas fa-user icon"></i>
            <input type="text" v-model="username" class="input-field" required />
          </div>
        </div>
        <div class="input-group">
          <label for="password" class="input-label">Password</label>
          <div class="input-wrapper">
            <i class="fas fa-lock icon"></i>
            <input type="password" v-model="password" class="input-field" required />
          </div>
        </div>
        <button type="submit" class="login-button">Login</button>
      </form>
      <p v-if="error" class="error-message">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getLocalIPAddress } from '../ipUtils'; // Import getLocalIPAddress

export default {
  data() {
    return {
      username: '',
      password: '',
      error: null,
    };
  },
  methods: {
    async login() {
      try {
        const localIP = getLocalIPAddress(); // Dynamically get local IP
        const apiBaseUrl = `https://${localIP}`; // Construct the API base URL
        const response = await axios.post(`${apiBaseUrl}/api/Auth/login`, {
          username: this.username,
          password: this.password,
        });
        localStorage.setItem('token', response.data.token);
        this.$router.push('/invoices');
      } catch (err) {
        this.error = 'Login failed. Please check your credentials.';
      }
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css'); /* استيراد الأيقونات */

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background: url('@/assets/images/background.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* منع التمرير */
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw; 
  height: 100vh; 
  backdrop-filter: blur(0px); /* زيادة تأثير التشويش */
  background: rgba(0, 0, 0, 0.5); /* خلفية شفافة */
}

.login-card {
  width: 100%;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.1); /* خلفية شفافة مع تأثير الزجاج */
  padding: 2.5rem;
  border-radius: 20px; /* زوايا مستديرة */
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5); /* ظل أكبر وأغمق */
  text-align: center;
  backdrop-filter: blur(10px); /* تأثير الزجاج */
  border: 1px solid rgba(255, 255, 255, 0.3); /* حدود خفيفة */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.login-card:hover {
  transform: translateY(-5px); /* رفع البطاقة عند المرور */
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.6); /* زيادة الظل عند المرور */
}

.login-title {
  font-size: 2.4rem;
  font-weight: 700;
  color: #ffffff; /* لون نص أبيض */
  margin-bottom: 1rem;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* تأثير ظل للنص */
}

.login-subtitle {
  font-size: 1.2rem;
  font-weight: 400;
  color: #eeeeee; /* لون رمادي فاتح */
  margin-bottom: 2.5rem;
}

.login-form .input-group {
  margin-bottom: 1.8rem;
  text-align: left;
}

.input-label {
  display: block;
  margin-bottom: 0.4rem;
  font-weight: 600;
  color: #ffffff; /* لون النص */
}

.input-wrapper {
  position: relative; /* لجعل الأيقونة والحقول متداخلة */
}

.icon {
  position: absolute;
  top: 50%;
  left: 15px; /* مسافة من اليسار */
  transform: translateY(-50%);
  color: #999999; /* لون الأيقونة */
  font-size: 1rem;
  pointer-events: none; /* منع التفاعل مع الأيقونة */
}

.input-field {
  width: 85%;
  padding: 0.9rem 1rem 0.9rem 2.5rem; /* مسافة لليسار لظهور الأيقونة */
  border: 1px solid #ddd; /* حدود خفيفة */
  background-color: rgba(255, 255, 255, 0.15); /* خلفية شفافة */
  border-radius: 12px; /* زوايا مستديرة */
  font-size: 1rem;
  color: #ffffff; /* لون النص */
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.input-field:focus {
  border-color: #007bff; /* لون الحدود عند التركيز */
  background-color: rgba(255, 255, 255, 0.25); /* تفتيح الخلفية عند التركيز */
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.3); /* تأثير التوهج */
}

.login-button {
  width: 100%;
  padding: 0.9rem;
  background: linear-gradient(45deg, #007bff, #00d2ff); /* زر متدرج الألوان */
  color: #ffffff; /* لون النص */
  border: none;
  border-radius: 12px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
}

.login-button:hover {
  background-color: #0056b3; /* لون زر عند المرور */
  box-shadow: 0 8px 20px rgba(0, 123, 255, 0.4); /* تأثير الظل */
  transform: translateY(-3px); /* رفع الزر عند المرور */
}

.error-message {
  margin-top: 1.2rem;
  color: #ff4d4d; /* لون أحمر فاتح */
  font-weight: bold;
}
</style>  