<template>
  <div class="user-management-container">
    <h2>User Management</h2>

    <div class="content-container">
      <!-- Add User Form -->
      <div class="form-container">
        <h3>Add User</h3>
        <form @submit.prevent="createUser">
          <div class="form-group">
            <label for="username">Username</label>
            <input id="username" v-model="username" placeholder="Enter username" required />
          </div>

          <div class="form-group">
            <label for="password">Password</label>
            <input id="password" type="password" v-model="password" placeholder="Enter password" required />
          </div>

          <div class="form-group">
            <label for="role">Role</label>
            <select id="role" v-model="role" required>
              <option value="Admin">Admin</option>
              <option value="Employee">Employee</option>
              <option value="User">User</option>
            </select>
          </div>

          <button type="submit" class="btn primary-btn">Add User</button>
        </form>

        <div v-if="validationErrors.length" class="error-messages">
          <p v-for="error in validationErrors" :key="error">{{ error }}</p>
        </div>

        <!-- Back Button -->
        <button @click="goBack" class="btn secondary-btn back-button">Back</button>
      </div>

      <!-- Display Users Section -->
      <div class="users-list-container">
        <button @click="getAllUsers" class="btn secondary-btn display-users-btn">Display All Users</button>

        <table v-if="users.length" class="user-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Roles</th>
              <th v-if="isAdmin">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.userName">
              <td>{{ user.userName }}</td>
              <td>{{ user.roles.join(', ') }}</td>
              <td v-if="isAdmin">
                <button @click="deleteUser(user.userName)" class="btn danger-btn">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
       
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {jwtDecode} from 'jwt-decode'; // For decoding JWT token to check if the user is an Admin
import { getLocalIPAddress } from '../ipUtils'; // Import the getLocalIPAddress function

export default {
  data() {
    return {
      username: '',
      password: '',
      role: 'User', // Default to User
      validationErrors: [], // To hold validation errors
      users: [], // To hold fetched users
      isAdmin: false, // To track if the current user is an admin
    };
  },
  methods: {
    async createUser() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          alert('Please log in first');
          return;
        }

        this.validationErrors = []; // Reset validation errors

        const localIP = getLocalIPAddress(); // Get the dynamic local IP
        const apiBaseUrl = `https://${localIP}`; // Dynamically construct the base URL

        // Create the user using the dynamic base URL
        await axios.post(`${apiBaseUrl}/api/User/create`, {
          userName: this.username,
          password: this.password,
          role: this.role,
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });

        alert('User created successfully');
        this.$router.push('/invoices');
      } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
          const errors = error.response.data.errors;
          this.validationErrors = Object.values(errors).flat();
        } else {
          console.error('Error creating user:', error.response ? error.response.data : error.message);
          alert('Failed to create user');
        }
      }
    },

    async getAllUsers() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          alert('Please log in first');
          return;
        }

        const localIP = getLocalIPAddress(); // Get the dynamic local IP
        const apiBaseUrl = `https://${localIP}`; // Dynamically construct the base URL

        // Fetch all users using the dynamic base URL
        const response = await axios.get(`${apiBaseUrl}/api/User/getAllUsers`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        this.users = response.data;
      } catch (error) {
        console.error('Error fetching users:', error.response ? error.response.data : error.message);
        alert('Failed to fetch users');
      }
    },

    async deleteUser(userName) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          alert('Please log in first');
          return;
        }

        const confirmed = confirm(`Are you sure you want to delete user ${userName}?`);
        if (!confirmed) {
          return;
        }

        const localIP = getLocalIPAddress(); // Get the dynamic local IP
        const apiBaseUrl = `https://${localIP}`; // Dynamically construct the base URL

        // Delete the user using the dynamic base URL
        await axios.delete(`${apiBaseUrl}/api/User/deleteUser/${userName}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        alert('User deleted successfully');
        this.getAllUsers(); // Refresh the list after deletion
      } catch (error) {
        console.error('Error deleting user:', error.response ? error.response.data : error.message);
        alert('Failed to delete user');
      }
    },

    goBack() {
      this.$router.push('/invoices'); // Adjust the route according to your needs
    },

    checkIfAdmin() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('Please log in first');
        return;
      }

      const decodedToken = jwtDecode(token);
      this.isAdmin = decodedToken.role === 'Admin';
    },
  },
  created() {
    this.checkIfAdmin();
  },
};
</script>




<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.user-management-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

.content-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.form-container, .users-list-container {
  flex: 1;
}

h2, h3 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #444;
}

input, select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: border-color 0.2s ease;
}

input:focus, select:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
}

button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.primary-btn {
  background-color: #27ae60;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.primary-btn:hover {
  background-color: #2ecc71;
}

.secondary-btn {
  background-color: #2980b9;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.secondary-btn:hover {
  background-color: #3498db;
}

.back-button {
  margin-top: 20px;
  display: block;
  width: 100%; /* Ensures it's full width */
}

.danger-btn {
  background-color: #e74c3c;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.danger-btn:hover {
  background-color: #c0392b;
}

.error-messages {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

.user-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  font-size: 16px;
}

.user-table th, .user-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
  font-weight: 600;
  color: #555;
}

.no-users-message {
  color: #888;
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
  }

  h2, h3 {
    font-size: 1.4rem;
  }

  .user-table th, .user-table td {
    font-size: 14px;
  }
}
</style>
