<template>
  <div class="container">
    <h2>إضافة فاتورة جديدة</h2>
    <form @submit.prevent="submitInvoice" class="form">
      <div class="input-group">
        <label for="customerName" class="input-label">اسم العميل:</label>
        <input type="text" v-model="customerName" class="input-field" required />
      </div>
      <div class="input-group">
        <label for="phoneNumber" class="input-label">رقم الهاتف:</label>
        <input type="text" v-model="phoneNumber" class="input-field" required />
      </div>
      <div class="input-group">
        <label for="address">العنوان:</label>
        <input type="text" v-model="address" class="input-field" required />
      </div>
      <div class="input-group">
        <label for="invoiceStatus" class="input-label">حالة الفاتورة:</label>
        <select v-model="invoiceStatus" class="input-field" @change="handleStatusChange">
          <option value="READY" class="status-ready">جاهز</option>
          <option value="IN_PROGRESS" class="status-progress">قيد التنفيذ</option>
          <option value="DELIVERED" class="status-delivered">تم التسليم</option>
          <option value="UNDEFINED" class="status-undefined">غير محدد</option>
        </select>
      </div>

      <div class="input-group" v-if="invoiceStatus === 'IN_PROGRESS'">
        <label for="estimatedTime" class="input-label">الوقت المتوقع:</label>
        <div class="time-selectors">
          <select v-model="estimatedHours" class="time-selector">
            <option v-for="n in 24" :key="n" :value="n - 1">{{ n - 1 }} ساعات</option>
          </select>
          <select v-model="estimatedMinutes" class="time-selector">
            <option v-for="n in [0, 15, 30, 45]" :key="n" :value="n">{{ n }} دقائق</option>
          </select>
        </div>
      </div>

      <div class="input-group">
        <label for="createdBy" class="input-label">أنشئت بواسطة:</label>
        <input type="text" v-model="createdBy" class="input-field" required />
      </div>
      <div class="input-group">
        <label for="salesPerson">مندوب المبيعات:</label>
        <input type="text" v-model="salesPerson" class="input-field" required />
      </div>
      <div class="input-group">
        <label for="notes" class="input-label">ملاحظات:</label>
        <textarea v-model="notes" class="input-field" placeholder="أضف أي ملاحظات إضافية هنا..." rows="3"></textarea>
      </div>
      <div class="button-group">
        <button type="submit" class="submit-button" v-if="canAddInvoice">إضافة فاتورة</button>
        <button type="button" @click="goBack" class="back-button">عودة</button>
      </div>
    </form>
    <p v-if="error" class="error-message">{{ error }}</p>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import {jwtDecode} from 'jwt-decode'; // Corrected import
import { getLocalIPAddress } from '../ipUtils'; // Import the getLocalIPAddress function

export default {
  data() {
    return {
      customerName: '',
      phoneNumber: '',
      address: '',
      invoiceStatus: 'READY', // Default to READY
      createdBy: '',
      salesPerson: '',
      notes: '',
      error: null,
      estimatedHours: 0,
      estimatedMinutes: 0,
      isAdmin: false,
      isEmployee: false,
    };
  },
  computed: {
    canAddInvoice() {
      return this.isAdmin || this.isEmployee;
    },
  },
  methods: {
    async submitInvoice() {
      if (!this.canAddInvoice) {
        this.error = "ليس لديك إذن لإضافة الفاتورة.";
        return;
      }

      try {
        const token = localStorage.getItem('token');

        const estimatedTime = this.invoiceStatus === 'IN_PROGRESS' 
          ? moment().add(this.estimatedHours, 'hours').add(this.estimatedMinutes, 'minutes').tz('Asia/Baghdad').toDate() 
          : null;

        const invoice = {
          customerName: this.customerName,
          phoneNumber: this.phoneNumber,
          address: this.address,
          invoiceStatus: this.invoiceStatus,
          createdBy: this.createdBy,
          salesPerson: this.salesPerson,
          notes: this.notes,
          estimatedTime,
        };

        const localIP = getLocalIPAddress(); // Get the dynamic local IP
        const apiBaseUrl = `https://${localIP}`; // Dynamically construct the base URL

        // Post the invoice using the dynamic base URL
        await axios.post(`${apiBaseUrl}/api/Invoices`, invoice, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        this.$router.push('/invoices');
      } catch (err) {
        this.error = 'فشل في إضافة الفاتورة. حاول مرة أخرى.';
        console.error(err);
      }
    },
    goBack() {
      this.$router.push('/invoices');
    }
  },
  async created() {
    try {
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);

      this.isAdmin = decodedToken.role === 'Admin';
      this.isEmployee = decodedToken.role === 'Employee';

      if (!this.canAddInvoice) {
        this.error = "ليس لديك إذن لإضافة الفاتورة.";
      }
    } catch (err) {
      console.error('Error decoding token', err);
      this.$router.push('/login');
    }
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background: url('@/assets/images/background.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.container {
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 30px;
  background: rgba(34, 47, 62, 0.85);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  text-align: center;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #00aaff;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.input-group {
  margin-bottom: 1.5rem;
  text-align: left;
  color: #00aaff;
}

.input-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #00aaff;
}

.input-field, .time-selector {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  background-color: rgba(255, 255, 255, 0.9);
  color: #0a0a0a;
}

.time-selectors {
  display: flex;
  gap: 10px;
}

.input-field:focus, .time-selector:focus {
  border-color: #74ebd5;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.submit-button, .back-button {
  width: 48%;
  padding: 0.75rem;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.submit-button {
  background-color: #34685e;
  color: #fff;
}

.submit-button:hover {
  background-color: #00c6ff;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.2);
}

.back-button {
  background-color: #ff4d4f;
  color: #fff;
}

.back-button:hover {
  background-color: #ff7875;
  box-shadow: 0 5px 15px rgba(255, 77, 79, 0.2);
}

.error-message {
  margin-top: 1rem;
  color: red;
  font-weight: bold;
}
</style>
