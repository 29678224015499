<template>
  <div class="container">
    <h2>Edit Invoice</h2>
    <form @submit.prevent="updateInvoice" class="form">
      <div class="input-group">
        <label for="customerName" class="input-label">Customer Name:</label>
        <input type="text" v-model="invoice.customerName" class="input-field" required />
      </div>
      <div class="input-group">
        <label for="phoneNumber" class="input-label">Phone Number:</label>
        <input type="text" v-model="invoice.phoneNumber" class="input-field" required />
      </div>
      <div class="input-group">
        <label for="address">Address:</label>
        <input type="text" v-model="invoice.address" class="input-field" required />
      </div>
      <div class="input-group">
        <label for="invoiceStatus">Invoice Status:</label>
        <select v-model="invoice.invoiceStatus" class="input-field">
          <option value="READY" class="status-ready">READY</option>
          <option value="IN_PROGRESS" class="status-progress">IN PROGRESS</option>
          <option value="DELIVERED" class="status-delivered">تم التسليم</option>
          <option value="UNDEFINED" class="status-undefined">UNDEFINED</option>
        </select>
      </div>

      <!-- Estimated Time: Show only if status is IN_PROGRESS -->
      <div v-if="invoice.invoiceStatus === 'IN_PROGRESS'" class="input-group">
        <label for="estimatedTime" class="input-label">Estimated Time:</label>
        <div class="time-selectors">
          <select v-model="estimatedHours" @change="clearAndSetTime" class="time-selector">
            <option v-for="n in 24" :key="n" :value="n - 1">{{ n - 1 }} Hours</option>
          </select>
          <select v-model="estimatedMinutes" @change="clearAndSetTime" class="time-selector">
            <option v-for="n in [0, 15, 30, 45]" :key="n" :value="n">{{ n }} Minutes</option>
          </select>
        </div>
      </div>

      <div class="input-group">
        <label for="salesPerson" class="input-label">Sales Person:</label>
        <input type="text" v-model="invoice.salesPerson" class="input-field" required />
      </div>
      <div class="input-group">
        <label for="notes" class="input-label">Notes:</label>
        <textarea v-model="invoice.notes" class="input-field" placeholder="Add any additional notes here..." rows="3"></textarea>
      </div>
      <div class="button-group">
        <button type="submit" class="submit-button">Update Invoice</button>
        <!-- Delete Button -->
        <button v-if="isAdmin" @click="deleteInvoice" class="delete-button">Delete Invoice</button>
      </div>
      <button @click="goBack" class="back-button">Back</button>
    </form>
    <p v-if="error" class="error-message">{{ error }}</p>
    <p v-if="timeError" class="error-message">{{ timeError }}</p>
  </div>
</template>

<script>
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import moment from 'moment-timezone';
import { getLocalIPAddress } from '../ipUtils'; // Import the getLocalIPAddress function

export default {
  data() {
    return {
      invoice: {
        customerName: '',
        phoneNumber: '',
        address: '',
        invoiceStatus: 'READY', // Default to READY
        salesPerson: '', // Added Sales Person field
        notes: '', // Added Notes field
        estimatedTime: null, // To store estimated time
      },
      isAdmin: false, // Track if the user is an admin
      error: null,
      timeError: null, // Track time-related errors
      estimatedHours: 0, // Default to 0 hours
      estimatedMinutes: 0, // Default to 0 minutes
      estimatedTimeSet: false // Variable to track if estimated time is set
    };
  },
  async created() {
    try {
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);

      // Check if the user is an admin
      this.isAdmin = decodedToken.role && decodedToken.role.includes('Admin');

      const invoiceId = this.$route.params.id;
      const localIP = await getLocalIPAddress(); // Get dynamic local IP
      const apiBaseUrl = `https://${localIP}`; // Dynamically construct the base URL

      // Fetch the invoice details using the dynamic base URL
      const response = await axios.get(`${apiBaseUrl}/api/Invoices/${invoiceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.invoice = response.data;

      // تحقق من قيمة estimatedTime إذا كانت موجودة واستخدمها كقيمة افتراضية
      if (this.invoice.estimatedTime && !this.isEstimatedTimeEmpty(this.invoice.estimatedTime)) {
        const estimatedTime = moment(this.invoice.estimatedTime);
        this.estimatedHours = estimatedTime.hours();
        this.estimatedMinutes = estimatedTime.minutes();
        this.estimatedTimeSet = true; // Indicate that estimated time has been set
      } else {
        // إذا لم تكن موجودة، اضبط الساعات والدقائق إلى 0
        this.estimatedHours = 0;
        this.estimatedMinutes = 0;
        this.estimatedTimeSet = true; // Indicate that default values have been set
      }
    } catch (err) {
      console.error(err);
      this.$router.push('/login');
    }
  },
  methods: {
    isEstimatedTimeEmpty(estimatedTime) {
      const time = moment(estimatedTime);
      return time.hours() === 0 && time.minutes() === 0 && time.seconds() === 0;
    },
    clearAndSetTime() {
      // إذا كانت الحالة IN_PROGRESS، احسب الوقت التقديري
      if (this.invoice.invoiceStatus === 'IN_PROGRESS') {
        this.invoice.estimatedTime = moment()
          .add(this.estimatedHours, 'hours')
          .add(this.estimatedMinutes, 'minutes')
          .tz('Asia/Baghdad')
          .toDate(); // استخدم منطقة Asia/Baghdad الزمنية
      } else {
        this.invoice.estimatedTime = null; // مسح الوقت التقديري إذا لم تكن الحالة IN_PROGRESS
      }
    },
    async updateInvoice() {
      try {
        // تحقق من أن الدقائق محددة وصحيحة
        if (this.estimatedMinutes === null || this.estimatedMinutes === undefined) {
          this.timeError = "Please specify the minutes for the estimated time.";
          return;
        } else {
          this.timeError = null; // Clear time error if everything is fine
        }

        const token = localStorage.getItem('token');
        const invoiceId = this.$route.params.id;
        const localIP = await getLocalIPAddress(); // Get dynamic local IP
        const apiBaseUrl = `https://${localIP}`; // Dynamically construct the base URL

        // إذا كانت الفاتورة في الحالة IN_PROGRESS، احسب الوقت التقديري
        if (this.invoice.invoiceStatus === 'IN_PROGRESS') {
          const estimatedTime = moment()
            .add(this.estimatedHours, 'hours')
            .add(this.estimatedMinutes, 'minutes')
            .tz('Asia/Baghdad')
            .toDate();
          this.invoice.estimatedTime = estimatedTime; // Ensure it's saved
        } else {
          this.invoice.estimatedTime = null; // Clear estimated time if status is not IN_PROGRESS
        }

        // Update the invoice using the dynamic base URL
        await axios.put(`${apiBaseUrl}/api/Invoices/${invoiceId}`, this.invoice, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.$router.push('/invoices');
      } catch (err) {
        console.error('Error updating invoice:', err);
        this.error = 'Error updating invoice. Please try again.';
      }
    },
    async deleteInvoice() {
      if (confirm('Are you sure you want to delete this invoice?')) {
        try {
          const token = localStorage.getItem('token');
          const invoiceId = this.$route.params.id;
          const localIP = await getLocalIPAddress(); // Get dynamic local IP
          const apiBaseUrl = `https://${localIP}`; // Dynamically construct the base URL

          // Delete the invoice using the dynamic base URL
          const response = await axios.delete(`${apiBaseUrl}/api/Invoices/${invoiceId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 204 || response.status === 200) {
            this.$router.push('/invoices'); // Redirect to the invoice list
          } else {
            throw new Error('Unexpected response status: ' + response.status);
          }
        } catch (err) {
          console.error('Error deleting invoice:', err);
          alert('Failed to delete invoice. Please try again.');
        }
      }
    },
    goBack() {
      this.$router.push('/invoices');
    }
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background: url('@/assets/images/background.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.container {
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 30px;
  background: rgba(34, 47, 62, 0.85);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  text-align: center;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #00aaff;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.input-group {
  margin-bottom: 1.5rem;
  text-align: left;
  color: #00aaff;
}

.input-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #00aaff;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  background-color: rgba(255, 255, 255, 0.9);
  color: #0a0a0a;
}

/* Custom dropdown styles */
.input-field select {
  background-color: #000; /* الخلفية سوداء */
  color: #fff; /* لون النص أبيض */
}

.input-field option {
  background-color: #000; /* لون الخلفية للأوبشن الأسود */
  color: #fff; /* لون النص الافتراضي */
}

.input-field option.status-ready {
  color: rgb(0, 255, 0); /* لون أخضر للنص */
}

.input-field option.status-progress {
  color: rgb(255, 255, 0); /* لون أصفر للنص */
}

.input-field option.status-undefined {
  color: rgb(255, 0, 0); /* لون أحمر للنص */
}

.input-field:focus {
  border-color: #74ebd5;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.submit-button, .delete-button {
  width: 48%;
  padding: 0.75rem;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.submit-button {
  background-color: #34685e;
  color: #fff;
}

.submit-button:hover {
  background-color: #00c6ff;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.2);
}

.delete-button {
  background-color: #ff4d4f;
  color: #fff;
}

.delete-button:hover {
  background-color: #ff7875;
  box-shadow: 0 5px 15px rgba(255, 77, 79, 0.2);
}

.back-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #00aaff;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.back-button:hover {
  background-color: #0088cc;
  box-shadow: 0 5px 15px rgba(0, 136, 204, 0.2);
}

.error-message {
  margin-top: 1rem;
  color: red;
  font-weight: bold;
}

.time-selectors {
  display: flex;
  gap: 10px;
}

.time-selector {
  width: 48%;
  padding: 0.75rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: rgba(255, 255, 255, 0.9);
  color: #0a0a0a;
}
</style>
